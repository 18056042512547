
import { Helmet } from 'react-helmet-async';

import offer3 from '../../img/tripwire/offer-1.png'
import offer2 from '../../img/tripwire/offer-2.png'
import offer1 from '../../img/tripwire/offer-3.png'

import tw1 from '../../img/tripwire/tw-1.png'
import tw2 from '../../img/tripwire/tw-2.png'
import tw3 from '../../img/tripwire/tw-3.png'
import tw41 from '../../img/tripwire/tw-4-1.png'
import tw42 from '../../img/tripwire/tw-4-2.png'
import tw5 from '../../img/tripwire/tw-5.png'
import tw6 from '../../img/tripwire/tw-6.png'


import { useSearchParams } from "react-router-dom";
import moment from 'moment'
import { useEffect, useState } from 'react';
import blackWordLogo from '../../img/logo/black-word-logo.png';
import { FunnelFooter } from '../../components/landing/FunnelFooter';

import {set,ref,push} from 'firebase/database'
import { funnelDB } from '../../api/firebase-funnel-config';
import $ from 'jquery'

import irregShape from "../../img/bg/irregular-bg.png"
import ModalThreeDayMasterClassShortened from '../../components/low-ticket-academy/ModalThreeDayMasterClassShortened';


export const ThreeDayMasterClass = (props) =>{

    var[subscribing,setSubscribing] = useState(false);
    var[offer,setOffer] = useState();

    var[hour,setHour] = useState(0)
    var[minute,setMinute] = useState(0)
    var[second,setSecond] = useState(0)

    const [pageID,setPageID] = useState()

    const [searchParams] = useSearchParams();

    useEffect(()=>{

        if(searchParams){
            if(searchParams.get("type") === 'register'){
                setOffer(1)
                setSubscribing(true)
            }
        }

    },[searchParams])

    useEffect(()=>{

        if(pageID){
            var now = moment().format('MMMM DD YYYY')

            if(pageID){

                $.getJSON("https://api64.ipify.org?format=json", function(e){

                    var cleanedIP = String(e.ip).split('.').join('-')

                    push(ref(funnelDB,`analytics/impressions/${pageID}/${now}`),e.ip).then(()=>{

                        set(ref(funnelDB,`analytics/unique/${pageID}/${now}/${cleanedIP}`),'VISITED').then(()=>{

                        })

                    })

                });

            }
        }else setPageID('three-day-master-class')

    },[pageID])

    useEffect(()=>{

        var eventTime= moment().add(1,'day').unix(); 
        var currentTime = moment(moment().month()+1 + '-' +moment().date()+ '-'+moment().year() ,'MM-DD-YY');
        var diffTime =  currentTime - eventTime;
        var duration = moment.duration(diffTime*1000, 'milliseconds');
        var interval = 1000;

        const inte = setInterval(() =>{
            duration = moment.duration(duration - interval, 'milliseconds');
            setHour(duration.hours() < 10?'0'+ String(duration.hours()):duration.hours())
            setMinute(duration.minutes() < 10?'0'+ String(duration.minutes()):duration.minutes())
            setSecond(duration.seconds() < 10?'0'+ String(duration.seconds()):duration.seconds())
        }, interval);

        return () => clearInterval(inte);

    },[])

    function joinClass(off){
        setOffer(off);
        setSubscribing(true);
    }

    return(
        <>
            <Helmet>
                <title>Trading Circle - Three Day Masterclass</title>
                <script>
                    fbq('track', 'ViewContent')
                </script>
            </Helmet>
            <div className="container-fluid animate__animated animate__fadeIn px-lg-2 px-2 py-4">



                <div className='row justify-content-center'>

                    <div className='col-12 text-center'>
                        <img className='d-none d-lg-inline darkmode-logo' src={blackWordLogo} width='20%' alt='logo'/>
                        <img className='d-inline d-lg-none darkmode-logo' src={blackWordLogo} width='70%' alt='logo'/>
                        <div className='row justify-content-center'>
                            <div className='col-lg-6 col-12'>
                                <hr className='blue-hr my-3' style={{opacity:'0.5'}}/>
                            </div>
                        </div>
                    </div>

                    <div className='col-12'>
                        <div className='row justify-content-center'>
                            <div className='col-lg-12 text-center px-lg-0'>
                                <p className='mb-3 bg-light-blue text-white py-2' style={{fontSize:'calc(8px + 0.8vw)',borderRadius:'15px'}}>In just <b>3 Days</b> discover the <b>magic of trading</b> by joining our <b>MasterClass</b>.</p>
                            </div>

                            <div className='col-lg-9 text-center'>
                                <h1 style={{fontSize:'calc(16px + 1.5vw)'}}><u className='text-light-blue black'>The Three-Day Forex Power Course</u> Build a <span className='text-success black'>Solid-Rock Foundation</span> for <span className='text-light-blue black'>Consistent Profit</span>  and <span className='text-light-blue black'>Financial Freedom</span></h1>
                                <p className='mt-2' style={{fontSize:'calc(8px + 0.8vw)'}}>(Do not <span className='text-light-blue bold'>RISK</span> your time on <span className='text-light-blue bold'>EXPENSIVE</span> and <span className='text-light-blue bold'>COMPLICATED TRAININGS</span>)</p>
                            </div>

                            <div className='col-12 my-lg-4 my-2'/>
                            
                            <div className='col-12'>
                                <div className='row justify-content-center'>
                                    <div className='col-lg-8'>
                                        <div className='row justify-content-center mb-5 mx-lg-0 mx-2'>
                                            <iframe style={{aspectRatio:'15.85/9',margin:0,padding:0,position:"relative",border:'5px solid var(--accent)',borderRadius:'30px'}} src="https://player.vimeo.com/video/1028713492?h=3592b5b6b1&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Website Walkthrough"></iframe>
                                            <script src="https://player.vimeo.com/api/player.js"></script>
                                        </div>
                                    </div>

                                    <div className='col-9 animate__animated animate__pulse animate__infinite animate__slow mb-5 d-lg-block d-none' data-aos="fade-up">
                                        <a href='#offers'>
                                            <button style={{fontSize:'28px'}} className='form-control btn-solid bold py-4 px-5 w-100 btn-funnel'>
                                                <i class="fa-solid fa-handshake-angle"></i> JOIN the MASTERCLASS!
                                                <p style={{fontSize:'16px'}}>Access the Masterclass</p>
                                            </button>
                                        </a>
                                    </div>

                                    <div className='col-11 d-lg-none d-block'>
                                        <a href='#offers'>
                                            <button style={{fontSize:'calc(0.75rem + 1 vw)'}} className='form-control btn-solid bold py-3 px-5 mb-4 w-100 animate__animated animate__pulse animate__infinite animate__slow btn-funnel'>
                                                <i class="fa-solid fa-handshake-angle"></i> JOIN the MASTERCLASS!
                                                <p style={{fontSize:'12px'}}>Access the Masterclass</p>
                                            </button>
                                        </a>
                                    </div>

                                    <div className='col-lg-6 col-11 align-self-center ms-lg-4 mt-lg-0 mt-3'>
                                        <div className='row justify-content-center align-self-center'>
                                            
                                            <p className='black text-center' style={{fontSize:'calc(14px + 0.6vw)'}}>In this <u>3-DAY MASTERCLASS</u> you will learn:</p>

                                            <div className='my-3 d-flex justify-content-center flex-column'>
                                                <div className='justify-content-center text-center'>
                                                    <p className='mb-3 bold' style={{fontSize:'calc(14px + 0.5vw)'}}><i style={{fontSize:'calc(20px + 0.65vw)'}} className='fas fa-check text-light-blue black'/> <b className='text-light-blue'>DAY 1:</b> "Forex Foundations: Your First Step to Wealth!"</p>
                                                    <p className='mb-3 bold' style={{fontSize:'calc(14px + 0.5vw)'}}><i style={{fontSize:'calc(20px + 0.65vw)'}} className='fas fa-check text-light-blue black'/> <b className='text-light-blue'>DAY 2:</b> "Trade Smarter: Master Your Platform Fast!"</p>
                                                    <p className='bold' style={{fontSize:'calc(14px + 0.5vw)'}}><i style={{fontSize:'calc(20px + 0.65vw)'}} className='fas fa-check text-light-blue black'/> <b className='text-light-blue'>DAY 3:</b> "Chart Like a Pro: Unlock Winning Strategies!"</p>
                                                </div>
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>
                            </div>

                            
                        </div>
                        
                    </div>

                    <div className='col-12'>
                        <div className='row justify-content-center'>
                            <div className='col-lg-6 col-11 story-container my-5'>
                                <h3 className='text-blue black mb-3 story-title'>Hi, I'm Alex Balingit, Founder of <u>Trading Circle Academy</u>.</h3>
                                <p>My goal is simple: to help millions of Filipinos achieve financial freedom through the financial market. It doesn't matter if you have zero knowledge, are a beginner, or already have experience! I will guide you to the life you've always wanted.</p>

                                <p><i>“Pano kaya to, kailangan ko matutunan to”</i> ito yung exact words ko nung nagt-trabaho pa ako as a ‘Signal Sender’ sa isang crypto academy no’n sa ibang bansa.</p>

                                <p>Dito nagsimula ang lahat.</p>
                            
                                <p>Sobra akong na-curious kung ano ba yung trading? Paano ba kumikita sa industriyang ‘yon?</p>

                                <p>Alam mo, bilang isang batang lumaki sa hindi mayaman na pamilya, kaya kong pasukin lahat ng trabaho.</p>

                                <p>Nag-benta na ako ng kung ano ano. Tinapay, sabon, Ice-Candy, you name it.</p>

                                <p>Nag apply din ako sa iba’t ibang trabaho online.</p>

                                <p className='end'>I tried so many things like…</p>

                                <ul>
                                    <li>Virtual Assistant</li>
                                    <li>Data Entry</li>
                                    <li>Video Editor</li>
                                    <li>Signal Sender sa isang crypto community</li>
                                    <li>At marami pang iba.</li>
                                </ul>

                                <p>Bakit nga ba sa murang edad, ang dami ko nang pinasok?</p>

                                <p>At bakit hindi ko muna inuna ang pag aaral ko?</p>

                                <p>The answer is simple.</p>

                                <h5 className='text-center black text-danger'>WALA KAMING PERA!</h5>
                                <div className='d-flex justify-content-center my-3'>
                                    <img className='w-100' src={tw1} alt='Flashback No Money'/>
                                </div>

                                <p>Swerte na kapag sobra sa 40 pesos ang baon ko sa school.</p>

                                <p>Ibigsabihin, naka-raket si papa.</p>

                                <p>Ang hirap ng buhay namin noon.</p>

                                <p>Minsan napapatanong na lang ako…</p>

                                <p>Should I quit?</p>

                                <p>Should I give up?</p>

                                <p>But if I do, I’d never find out.</p>

                                <p>Dahil sa dinami daming raket ang pinasok ko…</p>

                                <p>Sa isa lang nagbago ang buhay ko.</p>

                                <h3 className='text-center text-light-blue black mb-3'>📈 FOREX!</h3>

                                <p className='bold'>In Trading Forex, I was able to:</p>

                                <h4 className='text-center text-light-blue black mb-3'>💸 Secure 6 Digit of Income Monthly</h4>
                                <div className='d-flex justify-content-center mt-3 mb-5'>
                                    <img className='w-100' src={tw2} alt='Six Digit Testimony'/>
                                </div>

                                <h4 className='text-center text-light-blue black mb-3'>🚗 Buy my First Car</h4>
                                <div className='d-flex justify-content-center mt-3 mb-5'>
                                    <img className='w-100' src={tw3} alt='Car Testimony'/>
                                </div>

                                <h4 className='text-center text-light-blue black mb-3'>🏡 Renovate my House into a Modern Smart Home</h4>
                                <div className='d-block justify-content-center mt-3 mb-5'>
                                    <img className='w-100' src={tw41} alt='House Testimony'/>
                                    <img className='w-100' src={tw42} alt='House Testimony'/>
                                </div>

                                <h4 className='text-center text-light-blue black mb-3'>🏫 Studied in UST and LaSalle with my OWN INCOME</h4>
                                <div className='d-flex justify-content-center mt-3 mb-5'>
                                    <img className='w-100' src={tw5} alt='School Testimony'/>
                                </div>

                                <a href='#offers'>
                                    <button className='btn-funnel btn-solid py-2 w-100 animate__animated animate__pulse animate__infinite animate__slow'>
                                        <i className='fa fa-chevron-right'/><i className='fa fa-chevron-right'/> JOIN THE MASTERCLASS <i className='fa fa-chevron-left'/><i className='fa fa-chevron-left'/>
                                        <span className='d-block' style={{fontSize:'12px'}}>Access the Masterclass</span>
                                    </button>
                                </a>
                                
                                <p className='bold text-blue mt-5'>Pero behind my success...</p>

                                <p>sobrang daming struggle ang pinagdaanan ko sa trading – bago ko ma-formulate ang strategy na responsible sa kung anong meron ako ngayon.</p>

                                <p>At some point of my trading career naubos, nawasak, at nanlumo din ako.</p>

                                <p>Dahil ang totoo, ang pinaka-mahirap sa lahat ay magsimula, tapos hindi mo alam kung sino ang paniniwalaan mo.</p>

                                <p>Naalala ko nung nag-fund ako sa isang “Fund Manager” daw.</p>

                                <p>Nag-try na rin ako ng robot o EA.</p>

                                <p>Ang ending?</p>

                                <p className='text-danger bold'>I started losing everything...</p>

                                <p className='end ms-5'>❌ I lost my P250,000 LIFE SAVINGS</p>
                                <p className='end ms-5'>❌ Nagka-utang ako dahil naniwala ako na mababawi pa…</p>
                                <p className='end ms-5'>❌ I lost all of my online jobs dahil hindi ako maka-focus sa nawala ko</p>

                                <p className='mt-5'>Sobrang na depress talaga ako sa mga nangyari sakin.</p>

                                <p>Despite all of that, hindi ako sumuko.</p>

                                <p>Alam ko naman na hindi scam ang industriyang to. Ang scam? Yung mga taong pinagkatiwalaan ko.</p>

                                <p>Hanggang sa nag-grind ulit ako.</p>

                                <p>This time ang sabi ko sa sarili ko <i>“Ako na ang t-trabaho sa sariling trade ko”</i></p>

                                <p>That is when I started manual trading.</p>

                                <p>Hanggang sa isang araw, unti-unting resulta na ang nakikita ko.</p>

                                <p>From <span className='black text-light-blue'>P1,000+ profit</span> until it becomes <span className='black text-light-blue'>P10,000; P100,000</span> up to <span className='black text-light-blue'>half million of profit</span> in a month.</p>

                                <p>Nung nae-experience ko na ‘to.</p>

                                <p className='black text-light-blue'>That is the time when I desired more.</p>

                                <p>Alam ko na kaya kong tulungan ang mga nasa paligid ko. Hinding hindi ko sila hahayaan masapit ang sinapit ko.</p>
                                
                                <p>Ipagkakalat ko ang kagandahan ng industriyang to.</p>

                                <p>Ito ang dahilan kaya ko naisipan gawin ang <span className='text-light-blue black'>3 - DAY POWER COURSE</span>.</p>
                                
                                
                                <p className='text-success black'>Kasi sa Forex</p>

                                <p className='end ms-5'>✅ Magkakaroon ka ng Consistent Source of Income</p>
                                <p className='end ms-5'>✅ Sobrang daling aralin kahit Zero Knowledge ka pa lang</p>
                                <p className='end ms-5'>✅ Kahit naka-Phone ka ay pwedeng pwede</p>
                                <p className='end ms-5'>✅ Hindi time consuming</p>

                                <p>Imagine...</p>

                                <p><span className='black text-light-blue'>From</span> a solo trader, to an <span className='black text-light-blue'>Empire!</span></p>
                                
                                <p><span className='black text-light-blue'>From</span> <i>“Pano kaya to, kailangan ko matutunan to”</i> to helping <span className='black text-light-blue'>THOUSANDS OF FILIPINOS!</span> achieve financial stability.</p>
                                
                                {/*
                                <div className='d-flex justify-content-center mt-3 mb-5'>
                                    <img src='?' alt='Testimony Meetings'/>
                                </div>*/}

                                <p>So, how did I do it?</p>

                                <p>Again, IT'S SIMPLE.</p>

                                <p>It's with the help of the Forex Market</p>

                                <a href='#offers'>
                                    <button className='btn-funnel btn-solid py-2 w-100 animate__animated animate__pulse animate__infinite animate__slow'>
                                        <i className='fa fa-chevron-right'/><i className='fa fa-chevron-right'/> JOIN THE MASTERCLASS <i className='fa fa-chevron-left'/><i className='fa fa-chevron-left'/>
                                        <span className='d-block' style={{fontSize:'12px'}}>Access the Masterclass</span>
                                    </button>
                                </a>

                                <h4 className='text-center text-light-blue black mb-3 mt-5'>🎊 FOR THE PAST FIVE YEARS 🎊</h4>
                                <div className='d-flex justify-content-center mt-3 mb-5'>
                                    <img className='w-100' src={tw6} alt='Coach Alex Trading'/>
                                </div>

                                <p>I’ve studied the <span className='black text-light-blue'>INS</span> and <span className='black text-light-blue'>OUTS</span> of the Forex Market.</p>

                                <p className='text-success black'>LIKE:</p>

                                <p className='end ms-5'><i className='fa fa-check text-success'/> How Can You Earn Consistently In the Forex Market</p>
                                <p className='end ms-5'><i className='fa fa-check text-success'/> How I Formulate My Strategy that Would Help You Succeed</p>
                                <p className='end ms-5'><i className='fa fa-check text-success'/> Why Forex Market is the Best Financial Market</p>
                                <p className='end ms-5'><i className='fa fa-check text-success'/> How the HARD LESSONS BECAME EASY</p>
                            
                                <p>Now, if you are interested in how I did it…</p>

                                <h4 className='mb-3 mt-4'>I’ve <span className='text-light-blue black underline'>SUMMARIZED MY 5 YEARS OF EXPERIENCE</span> into a <span className='text-light-blue black underline'>3-DAY FOREX POWERFUL COURSE</span>.</h4>

                                <p className='text-success black mt-4'>I did this so that you will no longer…</p>

                                <p className='end ms-5'>🎁 WASTE YOUR TIME STUDYING ALONE.</p>
                                <p className='end ms-5'>🎁 NEVER GET LOST ON YOUR TRADING JOURNEY AGAIN.</p>
                                <p className='end ms-5'>🎁 LEARN FOREX MARKET AS FAST AS YOU CAN.</p>
                                <p className='end ms-5'>🎁 NEVER STRUGGLE FINDING A WORKING STRATEGY EVER AGAIN.</p>

                                <a href='#offers'>
                                    <button className='mt-3 btn-funnel btn-solid py-2 w-100 animate__animated animate__pulse animate__infinite animate__slow'>
                                        <i className='fa fa-chevron-right'/><i className='fa fa-chevron-right'/> JOIN THE MASTERCLASS <i className='fa fa-chevron-left'/><i className='fa fa-chevron-left'/>
                                        <span className='d-block' style={{fontSize:'12px'}}>Access the Masterclass</span>
                                    </button>
                                </a>

                            </div>

                            <div className='col-lg-4 d-lg-block d-none my-5'>

                                <div className='row justify-content-center position-sticky sticky-top pt-3 align-items-center' style={{height:'100vh'}}>
                                    <div onClick={()=>joinClass(1)} className='col-lg-4 col-10 card-container-blue card-hover px-5 pt-5 pb-4 text-center align-items-center' style={{minWidth:"350px",maxWidth:'350px'}}>
                                        
                                       
                                        <p className='pill-white black mb-2'><i className='fa-solid fa-star'/> RECOMMENDED</p>
                                        <h3 className='text-white black'>Best Choice</h3>
                                        <img className='mb-3 w-75' src={offer1} alt='icon'/>

                                        <p className='text-start text-white mb-2 black'>Offer Includes:</p>
                                        <p className='text-start text-white mb-2 bold'><i className='fa-solid fa-circle-check'/> 3 Day Forex Power Course</p>
                                        <p className='text-start text-white mb-2 bold'><i className='fa-solid fa-circle-check'/> One Month Compound Plan</p>
                                        <p className='text-start text-white bold'><i className='fa-solid fa-circle-check'/> Building Empire Strategy</p>

                                        <h3 className='text-center text-white mt-3 black'>₱ 1,997</h3>

                                        <div className='animate__animated animate__pulse animate__slow animate__infinite'>    
                                            <button onClick={()=>joinClass(1)} className='form-control btn-solid-white bold w-100 btn-funnel mt-3'><i class="fa-solid fa-check"></i> Get Course</button>
                                        </div> 

                                        <span className='black text-white mt-3 d-block'>
                                            {hour} : {minute} : {second}
                                        </span>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className='col-12'>

                        

                        {/*START: Training with Step by Step Desktop*/}
                        <div id='offers' className='row d-lg-block mt-2' data-aos="fade-up" data-aos-offset="500">
                            <div className='col-12 pt-2 pb-3'>

                                <div className='row justify-content-center' style={{marginTop:"50px"}}>

                                    <div className='col-lg-8 text-center mb-3'>
                                        <h1 className='saira p-0 m-0' style={{fontSize:"calc(1.4rem + 1.4vw)"}}>Choose an OFFER that fits your budget!</h1>
                                        <p className='text-muted mt-4 bold'>Customized for your convenience:</p>
                                    </div>

                                </div>

                                <div className='row justify-content-center d-flex mt-5 mb-5' style={{gap:"50px"}}>

                                    <div onClick={()=>joinClass(2)} className='col-lg-3 col-10 lt-card card-container card-hover p-lg-5 p-4 text-center d-flex align-items-center'>
                                        <div>
                                            
                                            <p className='pill-white black mb-2 invisible'>&nbsp;</p>
                                            <h2>Better Choice</h2>
                                            <img className='mb-3 w-100' src={offer2} alt='icon'/>
                                            
                                            <p className='text-start text-light-blue mb-2 bold'>Offer Includes:</p>
                                            <p className='text-start text-light-blue mb-2'><i className='fa-solid fa-circle-check'/> 3 Day Forex Power Course</p>
                                            <p className='text-start text-light-blue mb-2'><i className='fa-solid fa-circle-check'/> One Month Compound Plan</p>
                                            <p className='text-start text-light-blue mb-2 bold'>&nbsp;</p>

                                            <h3 className='text-center text-light-blue mt-3 bold'>₱ 1,497</h3>
                                            <button onClick={()=>joinClass(2)} className='form-control btn-outline bold w-100 btn-funnel mt-3'><i class="fa-solid"></i> Choose</button>
                                        </div>
                                    </div>

                                    <div onClick={()=>joinClass(1)} className='col-lg-4 col-10 lt-card card-container-blue card-hover p-lg-5 p-4 text-center align-items-center card-emphasized'>
                                        
                                       
                                        
                                        <p className='pill-white black mb-2'><i className='fa-solid fa-star'/> RECOMMENDED</p>
                                        <h2 className='text-white black'>Best Choice</h2>
                                        <img className='mb-3 w-100' src={offer1} alt='icon'/>

                                        <p className='text-start text-white mb-2 black'>Offer Includes:</p>
                                        <p className='text-start text-white mb-2 bold'><i className='fa-solid fa-circle-check'/> 3 Day Forex Power Course</p>
                                        <p className='text-start text-white mb-2 bold'><i className='fa-solid fa-circle-check'/> One Month Compound Plan</p>
                                        <p className='text-start text-white bold'><i className='fa-solid fa-circle-check'/> Building Empire Strategy</p>

                                        <h3 className='text-center text-white mt-3 black'>₱ 1,997</h3>

                                        <div className='animate__animated animate__pulse animate__slow animate__infinite'>    
                                            <button onClick={()=>joinClass(1)} className='form-control btn-solid-white bold w-100 btn-funnel mt-3'><i class="fa-solid fa-check"></i> Choose</button>
                                        </div> 

                                    </div>

                                    <div onClick={()=>joinClass(3)} className='col-lg-3 col-10 lt-card card-container card-hover p-lg-5 p-4 text-center align-items-center d-flex'>
                                        <div>
                                            
                                            <p className='pill-white black mb-2 invisible'>&nbsp;</p>
                                            <h2>Good Choice</h2>
                                            <img className='mb-3 w-100' src={offer3} alt='icon'/>
                                            
                                            <p className='text-start text-light-blue mb-2 bold'>Offer Includes:</p>
                                            <p className='text-start text-light-blue mb-2'><i className='fa-solid fa-circle-check'/> 3 Day Forex Power Course</p>
                                            <p className='text-start text-light-blue mb-2 bold'>&nbsp;</p>
                                            <p className='text-start text-light-blue mb-2 bold'>&nbsp;</p>

                                            <h3 className='text-center text-light-blue mt-3 bold'>₱ 997</h3>
                                            <button onClick={()=>joinClass(3)} className='form-control  btn-outline bold w-100 btn-funnel mt-3'><i class="fa-solid"></i> Choose</button>
                                        </div>
                                    </div>

                                </div>
                                
                            </div>
                        </div>
                        {/*END: Training with Step by Step Desktop*/}

        
                        
                        {/*START: Timer Section Desktop View*/}
                        <div className='row justify-content-center d-none d-lg-block mb-5' data-aos="fade-up">

                            <div className='col-12'>
                                <div className='row justify-content-center'>
        
                                    <div className='col-12'>
                                        <p className='text-muted saira bold text-center mb-3'>THIS OFFER EXPIRES IN</p>
                                    </div>

                                    <div className='col-lg-10 text-center'>

                                        <h1 className='black' style={{fontSize:"6.5vw"}}>
                                            {hour}
                                            <span className='saira text-light-blue me-5' style={{fontSize:"1.25vw"}}>HOURS</span>
                                            {minute}
                                            <span className='saira text-light-blue me-5' style={{fontSize:"1.25vw"}}>MINUTES</span>
                                            {second}
                                            <span className='saira text-light-blue' style={{fontSize:"1.25vw"}}>SECONDS</span>
                                        </h1>
                                    </div>

                                </div>
                            </div>
                            

                        </div>
                        {/*END: Timer Section Desktop View*/}

                        {/*START: Timer Section Mobile View*/}
                        <div className='row justify-content-center d-block d-lg-none' data-aos="fade-up">
                        

                            <div className='col-12' style={{marginTop:"25px"}}>
                                <p className='text-muted saira bold text-center mb-3'>THIS OFFER EXPIRES IN</p>
                            </div>

                            <div className='col-12 text-center'>

                                <h1 className='black' style={{fontSize:"10vw"}}>
                                    {hour} : {minute} : {second}
                                </h1>
                            </div>

                        </div>
                        {/*END: Timer Section Mobile View*/}

                    </div>

                </div>

            </div>

            <div className='d-lg-block d-none' style={{background:`url('${irregShape}')`,width:'125%',height:'1507px',position:'absolute',top:'-100px',zIndex:'-999',backgroundPosition:'center center',right:'-12.5%',backgroundSize:'cover',opacity:'0.3'}}/>
            <div className='d-lg-none d-block' style={{background:`url('${irregShape}')`,width:'100%',height:'1005px',position:'absolute',top:'-100px',zIndex:'-999',backgroundPosition:'center center',backgroundSize:'cover',opacity:'0.2'}}/>

            <div className='d-lg-block d-none' style={{background:`url('${irregShape}')`,rotate:'180deg',width:'125%',height:'1507px',position:'absolute',top:'1200px',zIndex:'-999',backgroundPosition:'center center',right:'-12.5%',backgroundSize:'cover',opacity:'0.3'}}/>
            <div className='d-lg-none d-block' style={{background:`url('${irregShape}')`,rotate:'180deg',width:'100%',height:'1005px',position:'absolute',top:'1200px',zIndex:'-999',backgroundPosition:'center center',backgroundSize:'cover',opacity:'0.2'}}/>

            <div className='d-lg-block d-none' style={{background:`url('${irregShape}')`,width:'125%',height:'1507px',position:'absolute',top:'2300px',zIndex:'-999',backgroundPosition:'center center',right:'-12.5%',backgroundSize:'cover',opacity:'0.3'}}/>
            <div className='d-lg-none d-block' style={{background:`url('${irregShape}')`,width:'100%',height:'1005px',position:'absolute',top:'2300px',zIndex:'-999',backgroundPosition:'center center',backgroundSize:'cover',opacity:'0.2'}}/>

            <div className='d-lg-block d-none' style={{background:`url('${irregShape}')`,rotate:'180deg',width:'125%',height:'1507px',position:'absolute',top:'3600px',zIndex:'-999',backgroundPosition:'center center',right:'-12.5%',backgroundSize:'cover',opacity:'0.3'}}/>
            <div className='d-lg-none d-block' style={{background:`url('${irregShape}')`,rotate:'180deg',width:'100%',height:'1005px',position:'absolute',top:'3600px',zIndex:'-999',backgroundPosition:'center center',backgroundSize:'cover',opacity:'0.2'}}/>

            <div className='d-lg-block d-none' style={{background:`url('${irregShape}')`,width:'125%',height:'1507px',position:'absolute',top:'4800px',zIndex:'-999',backgroundPosition:'center center',right:'-12.5%',backgroundSize:'cover',opacity:'0.3'}}/>
            <div className='d-lg-none d-block' style={{background:`url('${irregShape}')`,width:'100%',height:'1005px',position:'absolute',top:'4800px',zIndex:'-999',backgroundPosition:'center center',backgroundSize:'cover',opacity:'0.2'}}/>

            <div className='d-lg-block d-none' style={{background:`url('${irregShape}')`,rotate:'180deg',width:'125%',height:'1507px',position:'absolute',top:'6200px',zIndex:'-999',backgroundPosition:'center center',right:'-12.5%',backgroundSize:'cover',opacity:'0.3'}}/>
            <div className='d-lg-none d-block' style={{background:`url('${irregShape}')`,rotate:'180deg',width:'100%',height:'1005px',position:'absolute',top:'6200px',zIndex:'-999',backgroundPosition:'center center',backgroundSize:'cover',opacity:'0.2'}}/>

            <FunnelFooter/>

            <ModalThreeDayMasterClassShortened show={subscribing} onHide={()=>setSubscribing(false)} offer={offer}/>

        </>
    );

}