
import AOS from 'aos';
import 'aos/dist/aos.css';

//Utilities
import { BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';

//Firebase Services
import { studentAuth, studentDB } from './api/firebase-student-config'
import {useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';

// Pages
import { Login } from './pages/landing/Login';
import { Register } from './pages/landing/Register';
import { Pricing } from './pages/landing/Pricing';
import { onValue, ref, set } from 'firebase/database';
import { AcademyNavbar } from './components/academy/AcademyNavbar';
import { Module } from './pages/academy/Module';
import { Community } from './pages/academy/Community';
import { InnerCircle } from './pages/academy/InnerCircle';
import { Career } from './pages/academy/Career';
import { DemoChallenge } from './pages/academy/DemoChallenge';
import { Training } from './pages/academy/Training';
import { Tools } from './pages/academy/Tools';
import { Welcome } from './pages/academy/Welcome';
import { Profile } from './pages/academy/Profile';
import { CourseView } from './pages/academy/CourseView';
import { GroupChat } from './pages/academy/GroupChat';
import { PrivacyPolicy } from './pages/policies/PrivacyPolicy';
import { TermsAndConditions } from './pages/policies/TermsAndConditions';
import { RefundPolicy } from './pages/policies/RefundPolicy';
import { Disclaimer } from './pages/policies/Disclaimer';
import { Support } from './pages/landing/Support';
import { AboutUs } from './pages/landing/AboutUs';
import { Home } from './pages/landing/Home';
import { LandingNavbar } from './components/landing/LandingNavbar';
import { WhatsNext } from './pages/academy/WhatsNext';
import { AdvancedTraining } from './pages/academy/AdvancedTraining';
import { ElitePricing } from './pages/academy/ElitePricing';
import { Assessment } from './pages/academy/Assessment';
import { AssessmentResults } from './pages/academy/AssessmentResults';
import { RegisterFunnel } from './pages/landing/RegisterFunnel';
import { ProofFunnel } from './pages/landing/ProofFunnel';

import { LegalDocuments } from './pages/policies/LegalDocuments';

import $ from 'jquery'
import moment from 'moment';

import { FreeTraining } from './pages/funnels/FreeTraining';
import { Congratulations } from './pages/funnels/Congratulations';
import { Services } from './pages/funnels/Services';
import { Enrollment } from './pages/funnels/Enrollment';
import { Checkout } from './pages/funnels/Checkout';
import { EliteCheckout } from './pages/funnels/EliteCheckout';
import { Success } from './pages/funnels/Success';
import { Forum } from './pages/academy/Forum';
import { WorkshopDay1 } from './pages/funnels/WorkshopDay1';
import { WorkshopDay2 } from './pages/funnels/WorkshopDay2';
import { WorkshopDay3 } from './pages/funnels/WorkshopDay3';
import { LifetimeOffer } from './pages/funnels/LifetimeOffer';
import { FTPHInvitation } from './pages/funnels/FTPHInvitation';
import { StudentTestimonials } from './pages/funnels/StudentTestimonials';
import { TestimonialSubmission } from './pages/academy/TestimonialSubmission';
import { InnerCircleCopytradingTutorial } from './pages/funnels/InnerCircleCopytradingTutorial';
import { RecoverEnrollee } from './pages/landing/RecoverEnrollee';
import { TradingCircleFamilyShirt } from './pages/funnels/TradingCircleFamilyShirt';
import { TradingCircleFamilyShirtTracking } from './pages/funnels/TradingCircleFamilyShirtTracking';
import { VideoCopyright } from './pages/policies/VideoCopyright';
import { PriceIncrease } from './pages/funnels/PriceIncrease';
import { Sorry } from './pages/funnels/Sorry';
import { WealthCircleCheckout } from './pages/funnels/WealthCircleCheckout';
import { FreeTrainingCommunity } from './pages/funnels/FreeTrainingCommunity';
import { F2FTraining } from './pages/academy/F2FTraining';
import { ServicesCommunity } from './pages/funnels/ServicesCommunity';
import { ThreeDayMasterClass } from './pages/low-ticket-academy/ThreeDayMasterClass';
import { ThreeDayMasterClassCheckout } from './pages/low-ticket-academy/ThreeDayMasterClassCheckout';
import { LowTicketAcademy } from './pages/low-ticket-academy/LowTicketAcademy';
import { LowTicketAcademyNavbar } from './components/low-ticket-academy/LowTicketAcademyNavbar';
import { ThreeDayMasterClassDay4 } from './pages/low-ticket-academy/ThreeDayMasterClassDay4';


function App() {

  var [processing,setProcessing] = useState(true);
  var [student,setStudent] = useState(null);
  var [uid, setUID] = useState()

  var [canCheck,setCanCheck] = useState(false)
  
  var [darkMode,setDarkMode] = useState(false)

  useEffect(()=>{

    if(uid){

      onValue(ref(studentDB,`students/${uid}/theme`),(snapshot)=>{

        if(snapshot.exists()){
          if(snapshot.val() === 'DARK') setDarkMode(true)
          else setDarkMode(false)
        }else setDarkMode(false)

      })

    }else setDarkMode(false)

  },[uid])

  useEffect(() => {
    AOS.init();
    //(async () => await notifyUser())();
  }, [])

  useEffect(()=>{

    onAuthStateChanged(studentAuth,(student)=>{
    
      if(student){
        
        onValue(ref(studentDB,"students/"+student.uid),(snapshot)=>{
          if(snapshot.exists()){
            setUID(student.uid)
            setStudent(snapshot.val());
            setProcessing(false);
          }else{
            
            if(window.location.pathname !== '/recover-enrollee'){
              if(!window.location.pathname.includes('/enrollment') && !window.location.pathname.includes('/register') && !window.location.pathname.includes('/three-day-masterclass')){
                window.location.href = '/recover-enrollee';
              }
            }
            setProcessing(false);
          } 
        })
  
      }else setProcessing(false);
  
    })
  
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  
  useEffect(()=>{
    
    if(student && uid && !canCheck){
      $.getJSON("https://api64.ipify.org?format=json", function(e) {

        var time = moment().format('MMMM DD, YYYY hh:mm:ss a')

        var navigator_info = window.navigator;
        var screen_info = window.screen;
        var did = navigator_info.userAgent.replace(/\D+/g, '');
        //did += screen_info.height || '';
        //did += screen_info.width || '';
        did += screen_info.pixelDepth || '';
        console.log(did);

        var body = {
          ip:e.ip,
          logDate:time,
          deviceID:did
        }

        set(ref(studentDB,`students/${uid}/log`),body).then(()=>{
          console.log('im checking')
          setCanCheck(true)
        })
        
      });
    }else if(student && uid && canCheck){

      $.getJSON("https://api64.ipify.org?format=json", function(e) {

        var navigator_info = window.navigator;
        var screen_info = window.screen;
        var did = navigator_info.userAgent.replace(/\D+/g, '');
        //did += screen_info.height || '';
        //did += screen_info.width || '';
        did += screen_info.pixelDepth || '';
        console.log(did);

        if(student.log){
          if(e.ip !== student.log.ip || did !== student.log.deviceID){
            //signOut(studentAuth).then(()=>window.location.reload())
          }
        }

      })

    }

  },[student, uid,canCheck])
  
  return (
    <HelmetProvider>
      <>
        
        <link rel='stylesheet' href={darkMode?`${process.env.PUBLIC_URL}/style/App-Dark.css`:`${process.env.PUBLIC_URL}/style/App.css`}/>

        {!processing &&
        <>
          <BrowserRouter>

            {student && student.level !== undefined && window.self === window.top && <AcademyNavbar/>}

            {student && student.level === undefined && student.lowTicketLevel !== undefined && window.self === window.top && <LowTicketAcademyNavbar/>}

            <LandingNavbar/>

            <Routes>
              {student?
                <>
                  {student.level !== undefined?
                  <>
                  
                    <Route exact path='/' element={<Profile/>}/>
                    
                    <Route exact path='/faq' element={<></>}/>
                    <Route exact path='/module' element={<Module/>}/>
                    <Route exact path='/module/view' element={<CourseView/>}/>
                    <Route exact path='/module/assessment' element={<Assessment/>}/>
                    <Route exact path='/module/assessment/results' element={<AssessmentResults/>}/>
                    
                    <Route exact path='/community' element={<Community/>}/>
                    <Route exact path='/inner-circle' element={<InnerCircle/>}/>
                    <Route exact path='/career' element={<Career/>}/>
                    <Route exact path='/demo-challenge' element={<DemoChallenge/>}/>
                    <Route exact path='/training' element={<Training/>}/>
                    <Route exact path='/f2f-training' element={<F2FTraining/>}/>
                    <Route exact path='/tools' element={<Tools/>}/>
                    <Route exact path='/testimonial-submission' element={<TestimonialSubmission/>}/>

                    <Route exact path='/welcome' element={<Welcome/>}/>
                    <Route exact path='/whats-next' element={<WhatsNext/>}/>
                    
                    <Route exact path='/advanced-training' element={<AdvancedTraining/>}/>
                    <Route exact path='/advancedtraining' element={<Navigate to='/advanced-training'/>}/>

                    <Route exact path='/elite-pricing' element={<ElitePricing/>}/>

                    <Route exact path='/pricing' element={<Pricing/>}/>

                    <Route exact path='/chat' element={<Navigate to='/chat/standard'/>}/>
                    <Route exact path='/chat/standard' element={<GroupChat level='Standard' icon='fa-user'/>}/>
                    <Route exact path='/chat/elite' element={<GroupChat level='Elite' icon='fa-bolt'/>}/>
                    <Route exact path='/chat/inner-circle' element={<GroupChat level='Inner Circle' icon='fa-brands fa-discourse'/>}/>

                    <Route exact path='/forum' element={<Navigate to='/forum/standard'/>}/>
                    <Route exact path='/forum/standard' element={<Forum level='Standard' icon='fa-user'/>}/>
                    <Route exact path='/forum/elite' element={<Forum level='Elite' icon='fa-bolt'/>}/>
                    <Route exact path='/forum/inner-circle' element={<Forum level='Inner Circle' icon='fa-brands fa-discourse'/>}/>

                    <Route path='*' element={<Navigate to='/'/>}/>
                  </>
                  :student.lowTicketLevel !== undefined?
                  <>
                    <Route path='/' element={<LowTicketAcademy/>}/>
                    <Route exact path='/day-4' element={<ThreeDayMasterClassDay4/>}/>

                    <Route exact path='/faq' element={<></>}/>
                    
                    <Route path='*' element={<Navigate to='/'/>}/>
                  </>
                  :
                  <>
                    
                    <Route exact path='/pricing' element={<Pricing/>}/>
                    <Route path='*' element={<Navigate to='/pricing'/>}/>
                  </>
                  }
                </>
              :
              <>
                
                <Route exact path='/recover-enrollee' element={<RecoverEnrollee/>}/>
                <Route exact path="/login" element={<Login/>} />
                <Route exact path="/register" element={<Register/>} />
                
                <Route path='*' element={<Navigate to='/home'/>}/>
              </>
              }
              
              
              
              <Route exact path='/support' element={<Support/>}/>  
              <Route exact path='/about' element={<AboutUs/>}/>  
              <Route exact path='/home' element={<Home/>}/>

              <Route exact path='/policies' element={<Navigate to='/policies/privacy-policy'/>}/>
              <Route exact path='/policies/privacy-policy' element={<PrivacyPolicy/>}/>
              <Route exact path='/policies/video-copyright' element={<VideoCopyright/>}/>
              <Route exact path='/policies/terms-and-conditions' element={<TermsAndConditions/>}/>
              <Route exact path='/policies/refund-policy' element={<RefundPolicy/>}/>
              <Route exact path='/policies/disclaimer' element={<Disclaimer/>}/>
              <Route exact path='/policies/legal-documents' element={<LegalDocuments/>}/>
              
              <Route exact path="/register-funnel" element={<RegisterFunnel/>} />
              <Route exact path="/proof-funnel" element={<ProofFunnel/>} />

              {/* FUNNEL */}
              
              <Route exact path='/freetraining' element={<FreeTraining origin='Facebook'/>}/>
              <Route exact path='/congratulations' element={<Congratulations/>}/>
              <Route exact path='/services' element={<Services origin='Facebook'/>}/>
              <Route exact path='/enrollment' element={<Enrollment origin='Facebook'/>}/>
              <Route exact path='/checkout' element={<Checkout origin='Facebook'/>}/>

              <Route exact path='/freetraining-yt' element={<FreeTraining origin='YouTube'/>}/>
              <Route exact path='/congratulations-yt' element={<Congratulations/>}/>
              <Route exact path='/services-yt' element={<Services origin='YouTube'/>}/>
              <Route exact path='/enrollment-yt' element={<Enrollment origin='YouTube'/>}/>
              <Route exact path='/checkout-yt' element={<Checkout origin='YouTube'/>}/>

              <Route exact path='/elitecheckout' element={<EliteCheckout/>}/>
              <Route exact path='/wealthcirclecheckout' element={<WealthCircleCheckout/>}/>
              <Route exact path='/success' element={<Success/>}/>

              <Route exact path='/price-increase' element={<PriceIncrease origin='Facebook'/>}/>

              <Route exact path='/mini-workshop-day-1' element={<WorkshopDay1/>}/>
              <Route exact path='/mini-workshop-day-2' element={<WorkshopDay2/>}/>
              <Route exact path='/mini-workshop-day-3' element={<WorkshopDay3/>}/>

              <Route exact path='/lifetime-offer' element={<LifetimeOffer/>}/>
              <Route exact path='/ftph-invitation' element={<FTPHInvitation/>}/>
              <Route exact path='/inner-circle-copytrading-tutorial' element={<InnerCircleCopytradingTutorial/>}/>
              <Route exact path='/student-testimonials' element={<StudentTestimonials/>}/>

              <Route exact path='/trading-circle-family-shirt' element={<TradingCircleFamilyShirt/>}/>
              <Route exact path='/trading-circle-family-shirt/:id' element={<TradingCircleFamilyShirtTracking/>}/>

              <Route exact path='/sorry' element={<Sorry origin='Facebook'/>}/>

              {/* FUNNEL */}

              {/* EA FUNNEL */}
              <Route exact path='/freetraining-community' element={<FreeTrainingCommunity/>}/>
              <Route exact path='/services-community' element={<ServicesCommunity/>}/>
              {/* EA FUNNEL */}

              {/* LOW TICKET FUNNEL */}
              <Route exact path='/three-day-masterclass' element={<ThreeDayMasterClass/>}/>
              <Route exact path='/three-day-masterclass-checkout' element={<ThreeDayMasterClassCheckout/>}/>
              {/* LOW TICKET FUNNEL */}

              {/* FUNNEL REDIRECTS
              <Route exact path='/freetraining' element={<Redirect to='https://www.tradingcircletraining.com.ph/landing-page-opt-in-fb' />}/>
              <Route exact path='/congratulations' element={<Redirect to='https://www.tradingcircletraining.com.ph/thank-you-page-fb'/>}/>
              <Route exact path='/services' element={<Redirect to='https://www.tradingcircletraining.com.ph/free-training-fb'/>}/>
              <Route exact path='/enrollment' element={<Redirect to='https://www.tradingcircletraining.com.ph/enroll-fb'/>}/>
              <Route exact path='/checkout' element={<Redirect to='https://www.tradingcircletraining.com.ph/checkout-page-fb'/>}/>
              {/* FUNNEL REDIRECTS */}
              
            </Routes>
          </BrowserRouter>
        </>
        }
      </>
    </HelmetProvider>
  );

}

export default App;
