import Modal from 'react-bootstrap/Modal';

import {hasCorrectEmail, hasLetter, hasNumber, hasUppercase, toTitleCase } from '../../helper/formatter';
import { useState } from 'react';
import $ from 'jquery'
import { get, ref, set } from 'firebase/database';
import { adminDB } from '../../api/firebase-admin-config';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { studentAuth, studentDB} from '../../api/firebase-student-config';
import moment from 'moment';
import ModalLoading from '../general/ModalLoading';

import offer3 from '../../img/tripwire/offer-1.png'
import offer2 from '../../img/tripwire/offer-2.png'
import offer1 from '../../img/tripwire/offer-3.png'


export default function ModalThreeDayMasterClassShortened(props) {

  const [processing,setProcessing] = useState(false);

  const [firstName,setFirstName] = useState()
  const [lastName,setLastName] = useState()
  const [contactNumber,setContactNumber] = useState()
  const [discovery,setDiscovery] = useState()

  const [eaconomyAcc,setEaconomyAcc] = useState()

  const [emailAddress,setEmailAddress] = useState()
  const [password,setPassword] = useState()
  const [confirmPassword,setConfirmPassword] = useState()

  const [policyAgree,setPolicyAgree] = useState(false)
  
  function verifyFirstName(){

    var txtFirstName = $('#txtFirstName').val().trim();
    var errFirstName = $('#errFirstName');
    
    errFirstName.text('')
    setFirstName(null);

    if(txtFirstName.trim().length === 0) errFirstName.text('Blank spaces are not allowed.');
    else if(txtFirstName.length <= 1) errFirstName.text('Atleast 2 Characters.');
    else setFirstName(toTitleCase(txtFirstName));
        
  }

    function verifyLastName(){

      var txtLastName = $('#txtLastName').val().trim();
      var errLastName = $('#errLastName');
      
      errLastName.text('')
      setLastName(null);

      if(txtLastName.trim().length === 0) errLastName.text('Blank spaces are not allowed.');
      else if(txtLastName.length <= 1) errLastName.text('Atleast 2 Characters.');
      else setLastName(toTitleCase(txtLastName));

    }

    function verifyContactNumber(){

      var txtContactNumber = $('#txtContactNumber').val().trim();
      var errContactNumber = $('#errContactNumber');
      
      errContactNumber.text('')
      setContactNumber(null);

      if(txtContactNumber.trim().length === 0) errContactNumber.text('Blank spaces are not allowed.');
      else if(hasLetter(txtContactNumber)) errContactNumber.text('Letters are not allowed.');
      else if(txtContactNumber.length > 15) errContactNumber.text('Invalid Format.');
      else if(txtContactNumber.includes(' ')) errContactNumber.text('Spaces are not allowed')
      else setContactNumber(txtContactNumber);

    }

    function verifyDiscovery(){
        
      var slcDiscovery = $('#slcDiscovery').find(":selected").val();
      setDiscovery(slcDiscovery)
      
      setEaconomyAcc(null)
    }

    function verifyEAconomyAccount(){

      var txtEAconomyAccount = $('#txtEAconomyAccount').val().trim();
      var errEAconomyAccount = $('#errEAconomyAccount');
      
      errEAconomyAccount.text(' ')
      setEaconomyAcc(null)

      if(txtEAconomyAccount.length === 0){
          errEAconomyAccount.text("Don't leave blankspaces.")
          return;
      }

      get(ref(adminDB,`eaconomyAccounts/${txtEAconomyAccount}`)).then((snapshot)=>{
          if(snapshot.exists()){
              
              if(snapshot.val()) errEAconomyAccount.text("Account Already Enrolled")
              else{
                  errEAconomyAccount.text(' ')
                  setEaconomyAcc(txtEAconomyAccount)
              }

          }else{
              errEAconomyAccount.text("Invalid Account")
          }

      })

    }

    function verifyEmailAddress(){

      var txtEmailAddress = $('#txtEmailAddress').val();
      var errEmailAddress = $('#errEmailAddress');
      
      txtEmailAddress = txtEmailAddress.trim()

      errEmailAddress.text('')
      //$('#infoEmailAddress').text('')  
      setEmailAddress(null);

      if(txtEmailAddress.trim().length === 0) errEmailAddress.text('Blank spaces are not allowed.');
      else if(!hasCorrectEmail(txtEmailAddress.trimEnd())) errEmailAddress.text('Invalid Email Format.');
      else setEmailAddress(txtEmailAddress);
        

    }

    function verifyPassword(){

      var txtPassword = $('#txtPassword').val();
      var errPassword = $('#errPassword');
      
      errPassword.text('')
      setPassword(null);

      if(txtPassword.trim().length === 0) errPassword.text('Blank spaces are not allowed.');
      else if(txtPassword.length < 8) errPassword.text('Atleast 8 characters.');
      else if(!hasUppercase(txtPassword)) errPassword.text('Should have atleast 1 uppercase letter.')
      else if(!hasNumber(txtPassword)) errPassword.text('Should have atleast 1 number.')
      else setPassword(txtPassword);

      verifyConfirmPassword()

    }

    function verifyConfirmPassword(){

      var txtPassword = $('#txtPassword').val();

      var txtConfirmPassword = $('#txtConfirmPassword').val();
      var errConfirmPassword = $('#errConfirmPassword');
      
      errConfirmPassword.text('')
      setConfirmPassword(null);

      if(txtConfirmPassword !== txtPassword) errConfirmPassword.text('Password does not match.')
      else setConfirmPassword(txtConfirmPassword);

    }
    
    function verifyAgree(){
      var aPP = $('#chkAgreePrivacyPolicy').is(":checked")
      var aTC = $('#chkAgreeTermsConditions').is(":checked")
      setPolicyAgree(aPP && aTC)
    }

    function registerUser(){

      setProcessing(true);

      createUserWithEmailAndPassword(studentAuth,emailAddress,password).then((student)=>{

          $('#leadForm').trigger('submit')

          var registerDate = moment().format('MMMM DD, YYYY hh:mm:ss a')
  
          const body = {
              profile:{
                  name:{
                      firstName:firstName,
                      lastName:lastName
                  },
                  contactNumber:contactNumber,
                  emailAddress:emailAddress,
                  discovery:discovery,
                  enrollment:{
                      registerDate:registerDate,
                  },
                  preferredOffer:props.offer
              }
          }

          if(eaconomyAcc) body["eaconomyAccount"] = eaconomyAcc;
          

          set(ref(studentDB,'students/' + student.user.uid),body).then(()=>{  
            
            
            if(eaconomyAcc){
              set(ref(adminDB,`eaconomyAccounts/${eaconomyAcc}`),true).then(()=>{
                window.top.location.href = `/three-day-masterclass-checkout?offer=${props.offer}`;
              })
            }
            else window.top.location.href = `/three-day-masterclass-checkout?offer=${props.offer}`;
            
          }).catch((error)=>{
            alert(error.message);
            setProcessing(false);
          })

          
      }).catch((error)=>{
        if(error.code === 'auth/email-already-in-use'){

          signInWithEmailAndPassword(studentAuth,emailAddress,password).then((student)=>{

            set(ref(studentDB,'students/'+ student.user.uid+'/profile/preferredOffer'),props.offer).then(()=>{
              window.location.href = `/three-day-masterclass-checkout?offer=${props.offer}`
            }).catch((error)=>{
              alert(error.message)
              setProcessing(false)
            })
          }).catch((error)=>{
            alert(error.message)
            setProcessing(false)
          });

        }

        setProcessing(false);
      })

  }

  function setDefault(){
    setFirstName(null)
    setLastName(null)
    setContactNumber(null)
    setDiscovery(null)
    setEaconomyAcc(null)
    setEmailAddress(null)
    setPassword(null)
    setConfirmPassword(null)
  }

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop='static'
        onShow={()=>setDefault()}
      >
        
        <Modal.Header className='bg-blue' closeButton>
        
          <h4 className='saira text-center text-white m-0'><i className='fa fa-check'></i> 3 DAY MASTERCLASS</h4>

        </Modal.Header>

        <Modal.Body className='bg-white-blue'>

          <div className='row justify-content-center py-3'>
            <div className='col-lg-12 funnel-container'>
              <div className='row justify-content-center'>
                  <div className='col-lg-8 text-center align-self-center animate__animated animate__fadeIn creds-form-container'>
                      <div className='row justify-content-center'>
                          

                          <div className='col-7 mb-3'>
                            <h3 className='black text-light-blue'>{props.offer === 1?"Best Choice":props.offer === 2?"Better Choice":"Good Choice"}</h3>
                            <img alt='offer' className='w-100' src={props.offer === 1?offer1:props.offer === 2?offer2:offer3}/>
                            <h5 className='text-center text-light-blue black'>{props.offer === 1?"₱ 1,997":props.offer === 2?"₱ 1,497":"₱ 997"}</h5>
                          </div>
                          
                          <hr/>

                          <div className='col-12 p-0 animate__animated animate__fadeIn'>

                              <div className='row p-lg-0 ps-4 pe-4'>

                                  <div className='col-12 text-start mb-4  '>
                              
                                      <label className='saira bold text-blue mb-1' htmlFor="txtEmailAddress">Email Address</label>
                                      <div className="input-group">
                                          <div className="input-group-prepend">
                                              <span className="input-group-text"><i className='fa fa-user'/></span>
                                          </div>
                                          <input id="txtEmailAddress" onChange={()=>verifyEmailAddress()} type="text" className="form-control" placeholder="Enter Email"/>
                                      </div>
                                      <small id="errEmailAddress" className="form-text text-danger mb-3 mt-2"></small>
                                  </div>

                                  <div className='col-12 text-start mb-3'>
                                      
                                      <label className='saira bold text-blue mb-1' htmlFor="txtPassword">Password</label>
                                      <div className="input-group">
                                          <div className="input-group-prepend">
                                              <span className="input-group-text"><i className='fa fa-lock'/></span>
                                          </div>
                                          <input id="txtPassword" onChange={()=>verifyPassword()} type="password" className="form-control" placeholder="Enter Password"/>
                                      </div>
                                      <small id="errPassword" className="form-text text-danger text-center mb-3 mt-2"></small>

                                  </div>

                                  <div className='col-12 text-start mb-3'>
                                      
                                      <label className='saira bold text-blue mb-1' htmlFor="txtConfirmPassword">Confirm Password</label>
                                      <div className="input-group">
                                          <div className="input-group-prepend">
                                              <span className="input-group-text"><i className='fa fa-lock'/></span>
                                          </div>
                                          <input id="txtConfirmPassword" onChange={()=>verifyConfirmPassword()} type="password" className="form-control" placeholder="Enter Password Again"/>
                                      </div>
                                      <small id="errConfirmPassword" className="form-text text-danger text-center mb-3 mt-2"></small>

                                  </div>

                                  <label className='saira bold text-blue text-start mb-1 mt-2'>Full Name</label>

                                  <div className='col-lg-6 text-start mb-3'>
                              
                                      <input placeholder='First Name' id="txtFirstName" onChange={()=>verifyFirstName()} type="text" className="form-control"/>
                                      <small id="errFirstName" className="form-text text-danger"></small>

                                  </div>

                                  <div className='col-lg-6 text-start mb-3'>
                              
                                      <input placeholder='Last Name' id="txtLastName" onChange={()=>verifyLastName()} type="text" className="form-control"/>
                                      <small id="errLastName" className="form-text text-danger"></small>

                                  </div>

                                  <div className='col-12 text-start mb-3'>
                                    
                                    <label className='saira bold text-start text-blue mb-1'>Contact Number</label>

                                      <div className="input-group">
                                          <div className="input-group-prepend">
                                              <span className="input-group-text"><span className='text-blue bold fa fa-phone'></span></span>
                                          </div>
                                          <input placeholder='Enter Contact Number' id="txtContactNumber" onChange={()=>verifyContactNumber()} maxLength={15} type="text" className="form-control"/>
                                      </div>
                                      <small id="errContactNumber" className="form-text text-danger"></small>

                                  </div>

                                  <div className='col-12 text-start mb-4'>
                              
                                      <label className='saira bold text-blue mb-1' htmlFor="slcDiscovery">Where did you see Trading Circle?</label>
                                      <select className='form-select' id='slcDiscovery' onChange={()=>verifyDiscovery()}>
                                          <option value=''>-- Select One --</option>
                                          <option value='Facebook Ads'>Facebook Ads</option>
                                          <option value='Facebook Group'>Facebook Group</option>
                                          <option value='Forex Trading PH'>Forex Trading PH</option>
                                          <option value='TikTok'>TikTok</option>
                                          <option value='Instagram'>Instagram</option>
                                          <option value='YouTube Ads'>YouTube Ads</option>
                                          <option value='YouTube Main Channel'>YouTube Main Channel</option>
                                          <option value='FX Robot Empire'>FX Robot Empire / EAconomy</option>
                                          <option value='Friend Referral'>Friend Referral</option>
                                      </select>

                                  </div>

                                  {discovery === 'FX Robot Empire' &&
                                  <div className='col-12 text-start mb-3'>
                              
                                      <label className='saira bold text-blue mb-1' htmlFor="txtEAconomyAccount">FX Robot Empire / EAconomy Account #</label>
                                      <input id="txtEAconomyAccount" onChange={()=>verifyEAconomyAccount()} maxLength={15} type="text" className="form-control"/>
                                      <small id="errEAconomyAccount" className="form-text text-danger"></small>
                                      {eaconomyAcc && <small id="successEAconomyAccount" className="form-text text-success bold"> <i className='fa fa-check'/> Account Found (₱500 Discount Applied)</small>}

                                  </div>
                                  }


                                  <div className='col-12 text-start mb-1 mt-2 ms-3'>
                                      <input onChange={()=>verifyAgree()} id='chkAgreePrivacyPolicy' className='me-2 pointer' type="checkbox"/>
                                      <label className='saira text-blue mb-1'>Agree to our <a href='/policies/privacy-policy' target="_blank" className='text-blue'><b><u>Privacy Policy</u></b></a></label>
                                  </div>

                                  <div className='col-12 text-start mb-2 ms-3'>
                                      <input onChange={()=>verifyAgree()} id='chkAgreeTermsConditions' className='me-2 pointer' type="checkbox"/>
                                      <label className='saira text-blue mb-1'>Agree to our <a href='/policies/terms-and-conditions' target="_blank" className='text-blue'><b><u>Terms & Conditions</u></b></a></label>
                                  </div>

                              </div>
                              
                          </div>

                          <div className='col-12 p-lg-0 ps-4 pe-4'>
                              
                            <div className='row'>

                                <div className='col-12'>
                                    {firstName && lastName && discovery && emailAddress && password && confirmPassword && policyAgree?
                                    <button className='form-control saira m-0' onClick={()=>registerUser()}><i className='fa fa-check me-1'/>Enroll</button>
                                    :
                                    <button className='form-control saira m-0' disabled><i className='fa fa-check me-1'/>Enroll</button>
                                    }
                                </div>

                            </div>
                              

                          </div>

                      </div>

                  </div>
              </div>
            </div>

          </div>

          <form className='d-none' method='POST' action="https://assets.mailerlite.com/jsonp/1234508/forms/140934557407905011/subscribe" id="leadForm" target='lead_frame'>  
            <p className='bold text-blue mt-3'>First Name</p>
            <input name="fields[name]" value={firstName} className='form-control text-muted' type="text"/>

            <p className='bold text-blue mt-3'>Last Name</p>
            <input name="fields[last_name]" value={lastName} className='form-control text-muted' type="text"/>

            <p className='bold text-blue mt-3'>Email Address</p>
            <input name="fields[email]"  value={emailAddress} className='form-control text-muted' type="text"/>
          </form>

          <iframe title='lead_frame' name='lead_frame' className='d-none'/>

        </Modal.Body>

        <Modal.Footer className='bg-blue'>
        </Modal.Footer>

      </Modal>

      <ModalLoading show={processing} />

    </>
  );
}