
import { Helmet } from 'react-helmet-async';

import ModalLoading from '../../components/general/ModalLoading';
import { useEffect, useState } from 'react';

import { onValue, ref, set } from 'firebase/database';
import { onAuthStateChanged } from 'firebase/auth';
import { studentAuth, studentDB } from '../../api/firebase-student-config';
import { Footer } from '../../components/academy/Footer';

import profilePlaceholder from '../../img/placeholder/profile-placeholder.png';
import streakBG from '../../img/icon/streak.png';

import { adminDB } from '../../api/firebase-admin-config';
import ModalChangePassword from '../../components/academy/ModalChangePassword';
import ModalUpdateInfo from '../../components/academy/ModalUpdateInfo';
import { TodoList } from '../../components/academy/TodoList';
import { AssessmentResultList } from '../../components/academy/AssessmentResultList';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalConfirmation from '../../components/general/ModalConfirmation';

export const Profile = () =>{

    const [processing,setProcessing] = useState(false);

    var [student,setStudent] = useState(null);

    var [recentChapter,setRecentChapter] = useState()

    var [changingPassword,setChangingPassword] = useState(false)
    var [updatingInfo,setUpdatingInfo] = useState(false)

    const [platform,setPlatform] = useState();

    const copyClipboard = () => toast.success("Copied to Clipboard",{
        position:toast.POSITION.BOTTOM_CENTER,
        autoClose:500,
        icon:() =><i className="fa fa-check text-blue"/>
    });

    useEffect(()=>{

        onAuthStateChanged(studentAuth,(student)=>{
      
            if(student){
        
              onValue(ref(studentDB,"students/"+student.uid),(snapshot)=>{
                if(snapshot.exists()){

                    var s = snapshot.val()
                    s.uid = student.uid

                    setStudent(s);
                    setProcessing(false);
                }
              })
        
            }else setProcessing(false);
        
          })

    },[])

    useEffect(()=>{
        
        if(student && student.recentHistory){

            var chapter = String(student.recentHistory).split('.')[0]
            var episode = String(student.recentHistory).split('.')[1]
            
            onValue(ref(adminDB,`courses/${chapter}`),(snapshot)=>{

                if(snapshot.exists()){

                    var data = snapshot.val()
                    data.key = chapter
                    data.episode = episode

                    if(data.episodes[episode]){
                        data.episodeTitle = data.episodes[episode].title
                        setRecentChapter(data)
                    }else setRecentChapter(null)


                }

            })

        }

    },[student])

    function copyUID(){
        copyClipboard()
        navigator.clipboard.writeText(student.uid);
    }

    function markDoneTutorial(){

        setPlatform(null);
        setProcessing(true);

        set(ref(studentDB,`students/${student.uid}/tutorial/${platform}`),'DONE').then(()=>{
            setTimeout(()=>{
                setProcessing(false);
            },1000) 
        })

    }

    return(
        <>
            <Helmet>
                <title>Trading Circle - {student?student.profile.name.firstName +" "+ student.profile.name.lastName:"Profile"}</title>
            </Helmet>
            
            <div className="container-fluid animate__animated animate__fadeIn">

                <div className='row justify-content-center bg-dark-blue p-3 animate__animated animate__fadeInDown' style={{height:"200px",marginTop:"-75px",zIndex:'-1',position:'relative'}}/>
                
                <div className='row justify-content-center mb-5 animate__animated animate__fadeInDown d-lg-flex d-none' style={{marginTop:'-75px'}}>
                    <div className='col-lg-9 d-flex'>
                        <img src={student && student.profile.profilePictureURL?student.profile.profilePictureURL:profilePlaceholder} width="150px" alt='profile' style={{borderRadius:"100%"}}/>
                        <div className='row'>
                            <div className='col-12'>
                                <h1 className='text-white saira bold ms-4 mb-0' style={{marginTop:"27.5px"}}>{student && student.profile.name.firstName +" "+ student.profile.name.lastName} <span className='fa fa-edit pointer' style={{fontSize:'20px'}} onClick={()=>setUpdatingInfo(true)}/></h1>
                                <h4 className='text-blue saira ms-4 mb-0'>{student && student.level} Member</h4>
                                {student && student.wealthcircle && <h6 className='text-white pill d-inline px-3 py-1 ms-4'><i className='fa fa-star'/> Wealth Circle</h6>}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row justify-content-center mb-5 animate__animated animate__fadeInDown d-lg-none d-block' style={{marginTop:'-75px'}}>
                    <div className='col-12 text-center'>
                        <img src={student && student.profile.profilePictureURL?student.profile.profilePictureURL:profilePlaceholder} width="150px" alt='profile' style={{borderRadius:"100%"}}/>
                        <h1 className='text-blue saira bold mb-0 mt-2'>{student && student.profile.name.firstName +" "+ student.profile.name.lastName} <span className='fa fa-edit pointer' style={{fontSize:'16px'}} onClick={()=>setUpdatingInfo(true)}/></h1>
                        <h4 className='text-light-blue saira mb-0'>{student && student.level} Member</h4> 
                        {student && student.wealthcircle && <h6 className='text-white pill d-inline px-3 py-1'><i className='fa fa-star'/> Wealth Circle</h6>} 
                    </div>
                </div>
                
                <div className='row justify-content-center animate__animated animate__fadeInDown'>
                    <div className='col-lg-9'>
                        
                        {student &&
                        <div className='row justify-content-center' style={{gap:"20px"}}>
                            
                            {(!student.tutorial || !student.tutorial.desktop) &&
                            <div className='col-lg-9 col-md-11 d-lg-block d-none mb-5'>
                                <h3 className='bold saira text-center text-blue m-0 mb-3'>Website Tutorial <span style={{fontSize:'14px'}}>(<i className='fa fa-computer'/> Desktop)</span></h3>
                                <div className='video-container' style={{padding:"56.25% 0 0 0",position:"relative"}}><iframe src="https://player.vimeo.com/video/850178057?h=ffe8f8481a&amp%3Bbadge=0&amp%3Bautopause=0&amp%3Bplayer_id=0&amp%3Bapp_id=58479&fbclid=IwAR2w63nMT5KL7cTP9UABXSTUZjLjSsDzAw1eh9xCibma4-5feV4EzfxQJkc" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style={{position:"absolute",top:"0",left:"0",width:"100%",height:"100%"}} title="Website Walkthrough"></iframe></div>
                                <script src="https://player.vimeo.com/api/player.js"></script>
                                <div className='row mt-3 px-5'>
                                    <button className='btn-solid py-2' onClick={()=>setPlatform('desktop')}><i className='fa fa-check'/> Mark as Done</button>
                                </div>
                            </div>
                            }

                            {(!student.tutorial || !student.tutorial.mobile) &&
                            <div className='col-11 d-block d-lg-none mb-5'>
                                <h3 className='bold saira text-center text-blue m-0 mb-3'>Website Tutorial <span style={{fontSize:'14px'}}>(<i className='fa fa-mobile'/> Phone)</span></h3>
                                <div className='mobile-video-container' style={{padding:"177.5% 0 0 0",position:"relative"}}><iframe src="https://player.vimeo.com/video/850162309?h=4b0df6c3c8&amp%3Bbadge=0&amp%3Bautopause=0&amp%3Bplayer_id=0&amp%3Bapp_id=58479&fbclid=IwAR3BbVlPzPDEqYNnEgS9z9PdUGY45IP1RzZ2bHXEmu5KqHGpXloFfwXHk18" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style={{position:"absolute",top:"0",left:"0",width:"100%",height:"100%"}} title="Website Walkthrough"></iframe></div>
                                <script src="https://player.vimeo.com/api/player.js"></script>
                                <div className='row mt-3 px-5'>
                                    <button className='btn-solid py-2' onClick={()=>setPlatform('mobile')}><i className='fa fa-check'/> Mark as Done</button>
                                </div>
                            </div>
                            }

                        </div>
                        }

                        <div className='row justify-content-center' style={{gap:"20px"}}>

                            <div className='col-12'>
                                <h5 className='bold saira text-lg-start text-center text-blue m-0'>Profile Overview</h5>
                            </div>

                            <div className='col-lg-5 col-11 card-container-debossed p-4 profile-container'>

                                <p className='saira text-blue ms-4 bold' style={{fontSize:"18px"}}>Personal Information</p>
                                <hr className='blue-hr my-3' style={{opacity:'0.3'}}/>
                                <div className='row justify-content-center' style={{gap:'20px 0px'}}>
                                    
                                    <div className='col-lg-10 col-11'>
                                        <label className='saira text-blue bold' htmlFor='txtEmailAddress'>Email Address</label>
                                        <input id='txtEmailAddress' className='form-control' type="text" value={student ? student.profile.emailAddress:''} readOnly/>
                                    </div>

                                    <div className='col-lg-10 col-11'>
                                        <label className='saira text-blue bold' htmlFor='txtPassword'>Password <small className='pointer' style={{textDecoration:'underline'}} onClick={()=>setChangingPassword(true)}>(Change Password)</small></label>
                                        <input id='txtPassword' className='form-control' type="password" value={student ? student.profile.emailAddress:''} readOnly/>
                                    </div>

                                    <div className='col-lg-10 col-11'>   
                                        <label className='saira text-blue bold' htmlFor='txtBday'>Student ID <i className='fa fa-copy pointer' data-bs-toggle="tooltip" data-bs-placement="top" title="Copy to Clipboard" onClick={()=>copyUID()}/></label>
                                        <input id='txtSID' className='form-control' type="text" value={student ? student.uid:''} readOnly/>
                                    </div>

                                </div>

                                <hr className='blue-hr mt-4 mb-1' style={{opacity:'0.3'}}/>

                            </div>

                            {/*START: Learning Streak DESKTOP*/}
                            <div className='col-lg-3 col-11 card-container p-3 d-lg-block d-none' style={{minWidth:'355px',maxWidth:'355px'}}>

                                <p className='saira text-blue ms-4 bold' style={{fontSize:"18px"}}>Learning Streak</p>
                                <hr className='blue-hr mt-3 mb-2' style={{opacity:'0.3'}}/>

                                <div className='row h-100'>

                                    {student &&
                                    <div className='col-12 align-self-center h-100 text-center' style={{position:'relative'}}>
                                        <img src={streakBG} width='75%' alt='streak' style={{filter:`grayscale(${student.streak?0:1})`,opacity:student.streak?0.1*student.streak.days:0.1}}/>
                                        <h3 className={student.streak?'saira text-blue':'saira text-muted'} style={{position:'absolute',top:'95px',left:'140px',filter:`grayscale(${student.streak?1/student.streak.days:1})`}}>{student.streak?student.streak.days:0}<br/>Days</h3>
                                        <hr className='blue-hr mt-3 mb-2' style={{opacity:'0.3'}}/>
                                    </div>
                                    }

                                </div>

                            </div>
                            {/*END: Learning Streak DESKTOP*/}

                            {/*START: Learning Streak MOBILE*/}
                            <div className='col-lg-3 col-11 card-container p-3 d-lg-none d-block' style={{maxWidth:'355px'}}>

                                <p className='saira text-blue ms-4 bold' style={{fontSize:"18px"}}>Learning Streak</p>
                                <hr className='blue-hr mt-3 mb-2' style={{opacity:'0.3'}}/>

                                <div className='row h-100'> 

                                    {student &&
                                    <div className='col-12 align-self-center h-100 text-center' style={{position:'relative'}}>
                                        <img src={streakBG} width='75%' alt='streak' style={{filter:`grayscale(${student.streak?0:1})`,opacity:student.streak?0.1*student.streak.days:0.1}}/>
                                        <h2 className={student.streak?'saira text-blue':'saira text-muted'} style={{position:'absolute',top:'27.5%',left:'40%',fontSize:'28px',filter:`grayscale(${student.streak?1/student.streak.days:1})`}}>{student.streak?student.streak.days:0}<br/>Days</h2>
                                        <hr className='blue-hr mt-3 mb-2' style={{opacity:'0.3'}}/>
                                    </div>
                                    }

                                </div>

                            </div>
                            {/*END: Learning Streak MOBILE*/}

                            <div className='col-lg-3 col-11 card-container p-4' style={{minWidth:'355px',maxWidth:'355px'}}>
                                
                                <p className='saira text-blue ms-4 bold' style={{fontSize:"18px"}}>Recently Watching</p>
                                <hr className='blue-hr mt-3 mb-2' style={{opacity:'0.3'}}/>

                                <div className={recentChapter?'row':'row h-100'}> 
                                    
                                    {recentChapter?
                                    <div className='col-12 align-self-center'>
                                        <p className='text-center bold text-blue'>{recentChapter.key === "0"?"Introduction":recentChapter.key === "99"?"Free Elite Chapter":"Chapter "+recentChapter.key}</p>
                                        
                                        <a href={`/module/view?chapter=${recentChapter.key}&episode=${recentChapter.episode}`}>
                                            <img className='px-3 py-2' src={recentChapter.thumbnailURL} width="100%" alt='thumbnail' style={{borderRadius:'3rem'}}/>
                                        </a>
                                        <p className='text-center bold text-blue pointer' style={{textDecoration:'underline'}}><a className='text-blue' href={`/course/view?chapter=${recentChapter.key}&episode=${recentChapter.episode}`}>Episode {recentChapter.episode} : {recentChapter.episodeTitle}</a></p>
                                        <hr className='blue-hr mt-4 mb-1' style={{opacity:'0.3'}}/>

                                    </div>
                                    :
                                    <div className='col-12 align-self-center'>
                                        <p className='text-muted text-center saira mb-4' style={{fontSize:"72px",opacity:'0.5'}}>N/A</p>
                                    </div>
                                    }

                                </div>
                            </div>

                        </div>
                        
                        {student && <AssessmentResultList uid={student.uid}/>}
                        
                        {student && <TodoList uid={student.uid}/>}

                    </div>

                </div>

            </div>
            
            <Footer/>
            <ModalLoading show={processing} />
            <ModalChangePassword show={changingPassword} onHide={()=>setChangingPassword(false)}/>

            <ModalUpdateInfo show={updatingInfo} onHide={()=>setUpdatingInfo(false)} student={student}/>
            <ToastContainer limit={2}/>  


            <ModalConfirmation show={platform} title="<i class='fa fa-check'></i> Mark as Done?" message={`You are confirming that you have watched the tutorial for our <b>${platform} Website</b>.<br/><br/>You will NOT be able to see this video again once you confirm.`} onHide={()=>setPlatform(null)} onConfirm={()=>markDoneTutorial()}/>

        </>
    );

}